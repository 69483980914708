// AppWork files (JS + CSS)
require("../source/main.scss") // Include main stylesheet
require("../source/main") // Include main file

require("../source/stylesheets/custom.scss")
require("../source/stylesheets/users.scss")
require("../source/stylesheets/quickblox-video.scss")

// Styles for attachments (Patient attachments)
require("../../../vendor/assets/stylesheets/pages/tickets.scss")

// Rails stuff
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("local-time").start()

// require("dropzone")

require("trix")
require("@rails/actiontext")

window.Rails = Rails

import 'bootstrap'
import 'bootstrap-datepicker'
// import 'data-confirm-modal'
import 'timepicker'
import '../../../vendor/assets/libs/timepicker/timepicker.scss'

// import '../../../vendor/assets/libs/flatpickr/flatpickr.js'
// import '../../../vendor/assets/libs/flatpickr/flatpickr.scss'

import 'select2'
import '../../../vendor/assets/libs/select2/select2.scss'
// import 'select2/dist/css/select2.css' // original

// import '../../../vendor/assets/libs/fullcalendar/fullcalendar.js'
import '../../../vendor/assets/libs/fullcalendar/fullcalendar.scss'
import { Calendar, calendarPlugins } from '../../../vendor/assets/libs/fullcalendar/fullcalendar';

// FIXME: Need to solve issues around importing Appwork plugins
import moment from '../../../node_modules/moment';
import '../../../vendor/assets/libs/bootstrap-daterangepicker/bootstrap-daterangepicker.scss'

import 'video'

let calendarInstances = [];

$(document).on("turbolinks:before-cache", function() {
  $('.select2-hidden-accessible').select2('destroy')

  while (calendarInstances.length > 0) {
    var cal = calendarInstances.pop()
    cal.destroy()
  }
})

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('.dropdown-toggle').dropdown()
  // $('.datepicker').datepicker({autoclose: true})

  $('[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    // Call select2 again after turbolinks navigation
    $('[data-provide="select2"]').select2();
  });

  // This also needs to be run in app/javascript/controllers/application_controller.js
  // to attach to new DOM when StimulusReflex refreshes
  $('[data-provide=select2]').select2()

  // https://www.jonthornton.com/jquery-timepicker/
  $('[data-provide=timepicker]').timepicker({
    timeFormat: 'H:i',
    step: 30,
    minTime: '7:00',
    maxTime: '16:30',
    scrollDefault: 'now'
  });

  // Therapist appointments calendar
  // https://fullcalendar.io/docs#toc
  if ($('#calendar').length) {
    var calendarEl = document.getElementById('calendar');
    var calendar = new Calendar(calendarEl, {
      plugins: [
        calendarPlugins.bootstrap,
        calendarPlugins.timeGrid,
        calendarPlugins.dayGrid,
        calendarPlugins.interaction
      ],
      initialView: 'listWeek',
      timeZone: 'local',
      eventSources: [
        {
          url: '/events',
          method: 'GET',
          color: 'yellow',
          textColor: 'black'
        }
      ],
      // Bootstrap styling
      themeSystem: 'bootstrap',
      bootstrapFontAwesome: {
        close: ' ion ion-md-close',
        prev: ' ion ion-ios-arrow-back scaleX--1-rtl',
        next: ' ion ion-ios-arrow-forward scaleX--1-rtl',
        prevYear: ' ion ion-ios-arrow-dropleft-circle scaleX--1-rtl',
        nextYear: ' ion ion-ios-arrow-dropright-circle scaleX--1-rtl'
      },
      header: {
        left: 'title',
        center: 'dayGridMonth,timeGridWeek,timeGridDay',
        right: 'prev,next today'
      },
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      weekNumbers: true, // Show week numbers
      nowIndicator: true, // Show "now" indicator
      firstDay: 1, // Set "Monday" as start of a week
      businessHours: {
        dow: [1, 2, 3, 4, 5], // Monday - Friday
        start: '7:00',
        end: '18:00',
      },
      editable: false,
      eventLimit: true, // allow "more" link when too many events
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      slotLabelFormat: [
        {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        }
      ],
      views: {
        dayGrid: {
          eventLimit: 5
        }
      },
    });

    calendar.render();
    calendarInstances.push(calendar);
  }

  // Patient exercises calendar
  // https://fullcalendar.io/docs#toc
  if ($('#patient-calendar').length) {
    var calendarEl = document.getElementById('patient-calendar');
    var calendar = new Calendar(calendarEl, {
      plugins: [
        calendarPlugins.bootstrap,
        calendarPlugins.timeGrid,
        calendarPlugins.dayGrid,
        calendarPlugins.interaction
      ],
      initialView: 'listWeek',
      timeZone: 'local',
      eventSources: [
        {
          url: `/patients/${calendarEl.dataset.patientId}/calendar`,
          method: 'GET',
          color: 'yellow',
          textColor: 'black'
        }
      ],
      // Bootstrap styling
      themeSystem: 'bootstrap',
      bootstrapFontAwesome: {
        close: ' ion ion-md-close',
        prev: ' ion ion-ios-arrow-back scaleX--1-rtl',
        next: ' ion ion-ios-arrow-forward scaleX--1-rtl',
        prevYear: ' ion ion-ios-arrow-dropleft-circle scaleX--1-rtl',
        nextYear: ' ion ion-ios-arrow-dropright-circle scaleX--1-rtl'
      },
      header: {
        left: 'title',
        center: 'dayGridMonth,timeGridWeek,timeGridDay',
        right: 'prev,next today'
      },
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      weekNumbers: true, // Show week numbers
      nowIndicator: true, // Show "now" indicator
      firstDay: 1, // Set "Monday" as start of a week
      businessHours: {
        dow: [1, 2, 3, 4, 5], // Monday - Friday
        start: '7:00',
        end: '18:00',
      },
      editable: false,
      eventLimit: true, // allow "more" link when too many events
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      slotLabelFormat: [
        {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        }
      ],
      views: {
        dayGrid: {
          eventLimit: 5
        }
      },
    });

    calendar.render();
    calendarInstances.push(calendar);
  }

  if ($('#patient-sidebar-calendar').length) {
    var calendarEl = document.getElementById('patient-sidebar-calendar');
    var calendar = new Calendar(calendarEl, {
      plugins: [
        calendarPlugins.bootstrap,
        calendarPlugins.timeGrid,
        calendarPlugins.dayGrid
      ],
      initialView: 'listWeek',
      timeZone: 'local',
      eventSources: [
        {
          url: `/patients/${calendarEl.dataset.patientId}/calendar`,
          method: 'GET',
          color: 'yellow',
          textColor: 'black'
        }
      ],
      // Bootstrap styling
      themeSystem: 'bootstrap',
      bootstrapFontAwesome: {
        close: ' ion ion-md-close',
        prev: ' ion ion-ios-arrow-back scaleX--1-rtl',
        next: ' ion ion-ios-arrow-forward scaleX--1-rtl',
        prevYear: ' ion ion-ios-arrow-dropleft-circle scaleX--1-rtl',
        nextYear: ' ion ion-ios-arrow-dropright-circle scaleX--1-rtl'
      },
      header: {
        left: 'title',
        center: '',
        right: 'prev,next'
      },
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      weekNumbers: true, // Show week numbers
      nowIndicator: true, // Show "now" indicator
      firstDay: 1, // Set "Monday" as start of a week
      businessHours: {
        dow: [1, 2, 3, 4, 5], // Monday - Friday
        start: '7:00',
        end: '18:00',
      },
      editable: false,
      eventLimit: true, // allow "more" link when too many events
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      slotLabelFormat: [
        {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        }
      ],
      views: {
        dayGrid: {
          eventLimit: 5
        }
      },
    });

    calendar.render();
    calendarInstances.push(calendar);
  }

  if ($('#report-range-picker').length > 0) {
    let el = document.getElementById('report-range-picker')

    // // Date range picker for report/dashboard
    var start = moment(el.dataset.dateFrom);
    var end = moment(el.dataset.dateTo);

    function cb(start, end) {
      // TODO: Should we use Turbolinks.visit() instead? https://github.com/turbolinks/turbolinks#turbolinksvisit
      $('#report-range-picker').html(start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY'));
      var params = 'from=' + start.format('YYYY-MM-DD') + '&to=' + end.format('YYYY-MM-DD');
      // window.location.href = window.location.origin + window.location.pathname + params
      window.location.search = params
    }

    // Error when initializing calendar
    // Reproduce:
    // - remove setTimeout
    // - visit Patients (no turbolinks)
    // - visit a patient (id: 1)
    // Error in console
    setTimeout(function() {
      console.debug("Timer triggered to add daterangepicker")
      $('#report-range-picker').daterangepicker({
        startDate: start,
        endDate: end,
        maxDate: moment(),
        locale: {
          format: "DD.MM.YYYY",
          separator: " - "
        },
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 14 Days': [moment().subtract(13, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          'Last Two Months': [moment().subtract(2, 'month').startOf('month'), moment().endOf('month')]
        },
        opens: 'right'
      }, cb);
    }, 150)
  }

})

import "controllers"
