import ApplicationController from './application_controller'
// import { forEach } from 'lodash'

// You need to use data-controller="" with data-action="" and call stimulate()
// in script if you want to be using StimulusJs

export default class extends ApplicationController {
  static targets = [
    "state",
    "btnIncomplete",
    "btnComplete",
    "btnInactive"
  ]
  static values = {
    state: String
  }

  connect () {
    super.connect()
    console.debug("Connected to TherapyGoals controller")
    this.stateValue = this.stateTarget.value
  }

  complete (e) {
    console.log("Complete a goal")
    e.preventDefault()
    this.stateValue = "completed"
  }

  incomplete (e) {
    console.log("Mark goal as incomplete")
    e.preventDefault()
    this.stateValue = "incomplete"
  }

  inactive (e) {
    console.log("Deactivate a goal")
    e.preventDefault()
    this.stateValue = "inactive"
  }

  // callback
  stateValueChanged (newValue) {
    console.log("Value changed", newValue, this.stateValue)
    this.stateTarget.value = newValue // this.stateValue
    if (this.hasBtnCompleteTarget == true) {
      this.deactivateAllButtons()
      this.activateStateButton()
    }
  }

  // private
  deactivateAllButtons() {
    _.each([this.btnIncompleteTarget, this.btnCompleteTarget, this.btnInactiveTarget], function(btn) {
      btn.classList.remove("active")
    })
  }

  activateStateButton() {
    switch(this.stateValue) {
      case "":
      case "incomplete":
        this.activateSingleStateButton(this.btnIncompleteTarget);
        break;
      case "completed":
        this.activateSingleStateButton(this.btnCompleteTarget);
        break;
      case "inactive":
        this.activateSingleStateButton(this.btnInactiveTarget);
        break;
      default:
        console.warn("Could not identify the state")
    }
  }

  activateSingleStateButton(btn) {
    btn.classList.add("active")
  }
}
