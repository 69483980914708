import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {
  static targets = ["items"]
  static values = {
    resourceName: String
  }

  connect () {
    console.debug("Connected to Sortable controller")

    window.xxx = this

    // Don't do anything when there're no items to sort
    if (this.itemsTargets.length == 0) {
      console.warn("No sortable items found. Skipping sortable.")
      return false
    }

    super.connect()

    this.sortable = new Sortable(this.itemsTarget, {
      swapThreshold: 0.75,
      onEnd: (evt) => {
        const resourceName = this.resourceNameValue
        const param = resourceName ? `${resourceName}[position]` : 'position'
        const data = new FormData()
        data.append(param, evt.newIndex + 1)

        Rails.ajax({
          url: evt.item.dataset.sortableUpdateUrl,
          dataType: 'json',
          type: 'PATCH',
          data
        })
      }
    });
  }

  disconnect() {
    if (this.sortable != undefined) {
      this.sortable.destroy()
    }
    this.sortable = undefined
  }
}
