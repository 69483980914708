import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ["list"]

  showList (event) {
    event.preventDefault()

    let buttonText = "Show"

    if (this.data.get("visible") == "true") {
      this.listTarget.classList.add("d-none")
      this.data.set("visible", false)
      buttonText = "Patient tablet simulation"
    } else {
      this.listTarget.classList.remove("d-none")
      this.data.set("visible", true)
      buttonText = "Hide tablet simulation"
    }

    // Update button text
    event.target.value = buttonText
  }

}
