import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "name" ]

  // afterCreate (element, reflex) {
  //   this.nameTarget.value = '';
  //   this.nameTarget.focus()
  // }

  remove (event) {
    event.preventDefault();
    console.debug("Remove user from institution")

    // this.element.remove()
    this.stimulate('Users#remove_from_institution')
  }

  removeRole (event) {
    let roleRemoved = event.currentTarget.dataset.roleName
    // this.element.remove()

    this.stimulate('Users#remove_role', roleRemoved)
  }

  // beforeRemoveRole (element, reflex) {
  //   console.debug("Before removing role")
  // }

  // afterRemoveRole (element, reflex) {
  //   console.debug("Role should be removed")
  // }

}
