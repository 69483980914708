import ApplicationController from './application_controller'
// import { forEach } from 'lodash'

// You need to use data-controller="" with data-action="" and call stimulate()
// in script if you want to be using StimulusJs

export default class extends ApplicationController {
  static targets = []

  // static values = {
  //   exerciseFrequency: Number,
  //   exerciseFrequencyMode: Number
  // }

  connect () {
    super.connect()

    console.debug("Connected to ExerciseAssignment controller")
  }

  destroy (event) {
    console.debug("Going to destroy now from ExerciseAssignment")
  }

  // --------------------------------------

  // beforeCreate (element, reflex) {
  //   console.debug("Running beforeCreate", reflex)
  //   // document.activeElement.blur()
  // }

  // createSuccess(element, reflex, noop, reflexId) {
  //   console.debug("Running createSuccess", reflex)
  // }

  // afterCreate (element, reflex) {
  //   console.debug("Running afterCreate", reflex)
  // }


  // createSuccess(element, reflex, noop, reflexId) {
  //   console.log("add_exercise success", element, reflex, reflexId)
  // }

  // createError(element, reflex, error, reflexId) {
  //   console.error("add_exercise error", element, reflex, error, reflexId)
  // }

  // createHalted(element, reflex, noop, reflexId) {
  //   console.warn("add_exercise halted", element, reflex, reflexId)
  // }

}
