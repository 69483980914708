import ApplicationController from './application_controller'
// import { forEach } from 'lodash'

// You need to use data-controller="" with data-action="" and call stimulate()
// in script if you want to be using StimulusJs

import _ from 'lodash'

export default class extends ApplicationController {
  static targets = [
    "therapeutic", "serious", "robotic", "selectedType",
    "deviceType", "tymPablo", "ulfeRobot",
    "therapeuticType", "repetitions", "duration"
  ]
  static values = {}

  connect () {
    super.connect()
    console.debug("Connected to ExerciseType controller")

    this.hideAll()
    this.showSelected(this.selectedTypeTarget.value)
    this.showSelectedGameConfiguration(this.getSelectedDeviceType())
    this.changeTherapeuticType(this.therapeuticTypeTarget.value)
  }

  // change exercise type
  changeType (event) {
    this.hideAll()
    this.showSelected(event.target.value)
  }

  changeTherapeuticType (event) {
    console.debug("Toggle therapeutic type", this.therapeuticTypeTarget.value)

    this.setTherapeuticDisplay(this.therapeuticTypeTarget.value)
  }

  setTherapeuticDisplay(type) {
    this.repetitionsTarget.classList.add('d-none')
    this.durationTarget.classList.add('d-none')
    _.each(this.repetitionsTarget.querySelectorAll("input"), (e) => { e.disabled = true })
    _.each(this.durationTarget.querySelectorAll("input"), (e) => { e.disabled = true })

    if (type == "repetitions") {
      this.repetitionsTarget.classList.remove('d-none')
      _.each(this.repetitionsTarget.querySelectorAll("input"), (e) => { e.disabled = false })
    } else {
      this.durationTarget.classList.remove('d-none')
      _.each(this.durationTarget.querySelectorAll("input"), (e) => { e.disabled = false })
    }
  }

  // change device type (when game exercise type selected)
  changeDeviceType (event) {
    this.showSelectedGameConfiguration(event.target.value)
  }

  // Private

  hideAll () {
    const items = [
      this.therapeuticTarget,
      this.seriousTarget,
      this.roboticTarget,
      this.tymPabloTarget,
      this.ulfeRobotTarget
    ]

    _.forEach(items, (e) => e.classList.add('d-none') )
  }

  showSelected (value) {
    switch(value) {
      case 'therapeutic':
        this.therapeuticTarget.classList.remove('d-none');
        break;
      case 'serious':
        this.seriousTarget.classList.remove('d-none');
        break;
      case 'robotic':
        this.roboticTarget.classList.remove('d-none');
        break;
    }
  }

  showSelectedGameConfiguration (game) {
    switch(game) {
      case 'tym_pablo':
        this.tymPabloTarget.classList.remove('d-none');
        this.ulfeRobotTarget.classList.add('d-none');
        break
      case 'ulfe_robot':
        this.tymPabloTarget.classList.add('d-none');
        this.ulfeRobotTarget.classList.remove('d-none');
        break
    }
    this.disableConfigurationElements()
  }

  // To prevent values being submitted, disable form elements
  disableConfigurationElements () {
    let device = this.getSelectedDeviceType()
    const form_elements = 'input,select'

    if (device == 'tym_pablo') {
      _.each(this.tymPabloTarget.querySelectorAll(form_elements), (e) => { e.disabled = false })
      _.each(this.ulfeRobotTarget.querySelectorAll(form_elements), (e) => { e.disabled = true })
    } else if (device == 'ulfe_robot') {
      _.each(this.tymPabloTarget.querySelectorAll(form_elements), (e) => { e.disabled = true })
      _.each(this.ulfeRobotTarget.querySelectorAll(form_elements), (e) => { e.disabled = false })
    } else {
      console.debug("Can not determine configuration type. Device name: " + device)
    }
  }

  getSelectedDeviceType () {
    let selected = _.find(this.deviceTypeTargets, (e) => e.checked == true)

    return (selected != null) ? selected.value : null
  }
}
