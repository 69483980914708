import ApplicationController from './application_controller'
// import { forEach } from 'lodash'

// You need to use data-controller="" with data-action="" and call stimulate()
// in script if you want to be using StimulusJs

export default class extends ApplicationController {
  static targets = [
    "checkbox",
    "newForm"
  ]
  static values = {
    url: String
  }

  connect () {
    super.connect()
    console.debug("Connected to TherapyGoalsSidebar controller")
  }

  complete (e) {
    console.log("Complete a goal")
    e.preventDefault()

    let id = e.target.dataset.therapyGoalId
    let state = (e.target.checked == true) ? "completed" : "incomplete"

    const data = new FormData()
    data.append("state", state)
    data.append("therapy_goal_id", id)

    Rails.ajax({
      url: this.urlValue + `/${id}`,
      dataType: 'json',
      type: 'PATCH',
      data,
      success: function(response) {
        if (state == "completed") {
          e.target.closest('.goal').querySelector('.goal-notes').classList.add('strike-out')
        } else {
          e.target.closest('.goal').querySelector('.goal-notes').classList.remove('strike-out')
        }
      },
      error: function(response) {
        e.target.checked = !e.target.checked
      }
    })
  }

  toggleForm(e) {
    // show quick add form
    e.preventDefault()

    this.newFormTarget.classList.toggle("d-none")
    this.newFormTarget.querySelector('textarea').focus()
  }
}
