import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["allDay", "dayCheckbox"]
  static values = {}

  connect () {
    super.connect()
    console.debug("Connected to ProgramSchedule controller")
  }

  click (event) {
    let wrapper = event.target.closest(".dow-item")

    if (event.target.checked == true) {
      this.showInputFields(wrapper)
      wrapper.querySelector("input[name*='_destroy']").value = 0
    } else {
      this.clearFields(wrapper)
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.querySelector(".dow-inputs").style.display = 'none'
    }
  }

  remove (event) {
    event.preventDefault()
    let wrapper = event.target.closest(".dow-item")
    wrapper.querySelector("input[name*='_destroy']").value = 1
  }

  allDay (event) {
    let wrapper = event.target.closest(".dow-item")

    this.lockInputFields(wrapper, event.target.checked)
  }

  showInputFields (wrapper) {
    wrapper.querySelector(".dow-inputs").style.display = 'flex'
  }

  lockInputFields (wrapper, state) {
    _.each(wrapper.querySelectorAll("input[type='text']"), (el) => {
      el.disabled = state
      this.clearFields(wrapper)
    })
  }

  clearFields (wrapper) {
    _.each(wrapper.querySelectorAll("input[type='text']"), (el) => el.value = '')
  }
}
