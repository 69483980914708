import ApplicationController from './application_controller'
// import { forEach } from 'lodash'

// You need to use data-controller="" with data-action="" and call stimulate()
// in script if you want to be using StimulusJs

// import _ from 'lodash'

export default class extends ApplicationController {
  static targets = ["links", "template", "autofocus"]
  static values = {}

  connect () {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    console.debug("Connected Stimulus nested-form controller")
  }

  add_association (event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)

    if (this.hasAutofocusTarget) {
      this.autofocusTargets[this.autofocusTargets.length - 1].focus()
    }
  }

  remove_association (event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}
