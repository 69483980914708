import consumer from "./consumer"

consumer.subscriptions.create("Noticed::NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('Connected to Notification channel');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('Disconnected Notification channel');
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log("Received notification data", data)

    // Update NOTIFICATIONS COUNT

    let notificationsMenu = document.querySelector('.demo-navbar-notifications')
    let notificationsBadge = notificationsMenu.querySelector('.badge-dot')

    notificationsMenu.querySelector(".list-group").insertAdjacentHTML("afterbegin", data)
    notificationsBadge.classList.remove("d-none")
  }
});
