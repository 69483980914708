import ApplicationController from './application_controller'
import PerfectScrollbar from 'perfect-scrollbar';

import * as QB from 'quickblox/quickblox'

// You need to use data-controller="" with data-action="" and call stimulate()
// in script if you want to be using StimulusJs

// Development
// const CREDENTIALS = {
//   'appId': '86286',
//   'authKey': 'HUvmFRa9zZH2ac8',
//   'authSecret': '89kHSuATGCJdrTK'
// };

// Production
const CREDENTIALS = {
  'appId': '95159',
  'authKey': 'c7M8UTs5csfuAtR',
  'authSecret': 'ZUFaVhh5KPUX3FV'
};

const APP_CONFIG = {
  debug: false,
  webrtc: {
    answerTimeInterval: 60,
    dialingTimeInterval: 5,
    disconnectTimeInterval: 35,
    statsReportTimeInterval: 5
  }
};

export default class extends ApplicationController {
  static targets = [
    "messageReceivedTemplate",
    "messageSentTemplate",
    "messagesContainer",
    "message",
    "messageBtn",
    "stopVideoBtn"
  ]
  static values = {
    patientId: Number,
    therapistId: Number,
    therapistLogin: String,
    therapistPassword: String,
    videoEnabled: Boolean
  }

  connect () {
    super.connect()
    console.debug("Connected to Quickblox Chat controller")
    QB.init(CREDENTIALS.appId, CREDENTIALS.authKey, CREDENTIALS.authSecret, APP_CONFIG);

    this.setupQuickblox()

    if (this.videoEnabledValue) {
      this.setupVideoHandlers()
    }
  }

  disconnect () {
    console.debug("Cleaning up video")
    this.stopVideoCall()
  }

  setupQuickblox () {
    let loginCredentials = { login: this.therapistLoginValue, password: this.therapistPasswordValue };

    QB.createSession(loginCredentials, (err, result) => {
      if (err) {
        console.error("Could not create session", err)
      } else {
        this.currentSession = result
        console.debug('Session created', this.currentSession)

        let userCredentials = { userId: this.currentSession.user_id, password: this.therapistPasswordValue };

        QB.chat.connect(userCredentials, (error, contactList) => {
          if (error) {
            console.error("Can't connect to chat", error)
          } else {
            console.debug("Chat connected")
            console.debug("contactList", contactList)
            this.contactList = contactList

            this.initializeQBDialog()

            QB.chat.onMessageListener = this.onMessage.bind(this);

            QB.chat.onSentMessageCallback = function (messageLost, messageSent) {
              console.debug("Message was sent/lost", messageLost, messageSent);
            };
          }
        });

        if (this.videoEnabledValue == true) {
          console.warn("videoEnabledValue: true")
          QB.webrtc.onCallListener = this.onCallListener.bind(this)
          QB.webrtc.onUpdateCallListener = this.onUpdateCallListener.bind(this)
        }
      }
    });
  }

  setupVideoHandlers() {
    QB.webrtc.onRemoteStreamListener = function(session, userId, remoteStream) {
      // attach the remote stream to DOM element
      console.debug("Event: onRemoteStreamListener", userId, remoteStream)
      session.attachMediaStream("remoteVideo", remoteStream);
    };

    QB.webrtc.onAcceptCallListener = function(session, user_id, stats, error) {
      console.debug("Event: onAcceptCallListener", session, user_id, stats, error)
    }

    QB.webrtc.onRejectCallListener = function(session, user_id, stats, error) {
      console.debug("Event: onRejectCallListener", session, user_id, stats, error)
    }

    QB.webrtc.onUserNotAnswerListener = function(session, user_id, stats, error) {
      console.debug("Event: onUserNotAnswerListener", session, user_id, stats, error)
    }

    QB.webrtc.onSessionConnectionStateChangedListener = function onSessionConnectionStateChangedListener(session, userId, connectionState) {
      console.debug('onSessionConnectionStateChangedListener.');
    }
  }

  renderMessage (msg) {
    let timestamp = null
    if (msg.extension.date_sent != undefined) {
      timestamp = new Date(parseInt(msg.extension.date_sent) * 1000)
    } else {
      timestamp = new Date()
    }
    let object = {
      message: {
        sender: 'Patient name',
        content: msg.body,
        timestamp: timestamp.toLocaleDateString("sl-SI") + ` ${this.getTime(timestamp)}`
      }
    }

    let html = null
    if (msg.recipient_id == undefined || msg.recipient_id == this.therapistIdValue) {
      html = _.template(this.messageReceivedTemplateTarget.innerHTML)(object)
    } else {
      html = _.template(this.messageSentTemplateTarget.innerHTML)(object)
    }
    this.messagesContainerTarget.insertAdjacentHTML('beforeend', html)
  }

  // called on every keydown event
  submitMessage (event) {
    if (event.keyCode == 13) {
      this.sendMessage(event)
    }
  }

  sendMessage (event) {
    event.preventDefault()
    let opponentId = this.patientIdValue;
    let message = {
      type: "chat",
      body: this.messageTarget.value,
      recipient_id: opponentId,
      extension: {
        save_to_history: 1,
        dialog_id: this.dialogInstance._id
      },
      markable: 1
    };

    try {
      console.debug("Sending message", opponentId, message);
      message.id = QB.chat.send(opponentId, message);
      console.debug("Message id", message)
    } catch (e) {
      if (e.name === 'ChatNotConnectedError') {
        // not connected to chat
        console.error('Not connected to the chat', e)
      } else {
        console.error('Some other error', e)
      }
    }

    this.renderMessage(message)
    this.positionScroll()

    // Clear input
    this.messageTarget.value = ""
  }

  // INTERNAL

  initializeQBDialog () {
    console.debug("Initializing dialog with patient", this.patientIdValue);
    var params = {
      type: 3,
      occupants_ids: [this.patientIdValue]
    };

    QB.chat.dialog.create(params, (error, dialog) => {
      console.debug('Dialog instance fetched', dialog)
      this.dialogInstance = dialog
      this.fetchHistory()
    });
  }

  // Quickblox callbacks
  onMessage (userId, message) {
    console.debug("Message received/sent", userId, message)
    this.renderMessage(message)
    this.positionScroll()
  }

  getTime (time) {
    var date = new Date(time),
        hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
        minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

    return hours + ':' + minutes;
  };

  fetchHistory () {
    var params = {
      chat_dialog_id: this.dialogInstance._id,
      sort_asc: 'date_sent',
      limit: 100,
      skip: 0
    };

    QB.chat.message.list(params, (error, messages) => {
      if (error != undefined) {
        console.error("Problem fetching message list from Quickblox", error)
      }

      _.each(messages.items, (msg) => {
        var newMsg = {
          body: msg.message,
          recipient_id: msg.recipient_id,
          extension: {
            date_sent: msg.date_sent
          }
        }
        this.renderMessage(newMsg)
      })

      this.ps = new PerfectScrollbar(this.messagesContainerTarget)
      this.positionScroll()
    });
  }

  positionScroll () {
    this.messagesContainerTarget.scrollTop = this.messagesContainerTarget.scrollHeight
  }

  onCallListener (session, extension) {
    console.debug("onCallListener: Somebody is calling you", session, extension)
    this.currentSession = session;
    // this.createVideoSession();
  }

  onUpdateCallListener (session, extension) {
    console.debug("onUpdateCallListener: Somebody is calling you", session, extension)
    this.currentSession = session;
    // this.createVideoSession();
  }

  startVideoCall (event) {
    this.createVideoSession()
    this.videoControlsEnabled(true)
  }

  stopVideoCall (event) {
    this.stopVideoSession()
    this.videoControlsEnabled(false)
  }

  // Toggle mute/video
  disableSource (event) {
    let source = event.target.dataset['target']
    console.debug('Toggling source', source)

    if (event.target.classList.contains('active')) {
      this.videoSession.mute(source)
      event.target.classList.remove('active')
    } else {
      this.videoSession.unmute(source)
      event.target.classList.add('active')
    }
  }

  videoControlsEnabled (enabled) {
    if (!this.videoEnabledValue) { return }

    this.stopVideoBtnTarget.disabled = !enabled
    let elements = document.getElementsByClassName('video-control')
    _.each(elements, function(el) { el.disabled = !enabled })
  }

  createVideoSession () {
    var calleesIds = [this.patientIdValue];
    var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
    var additionalOptions = {};

    var session = QB.webrtc.createNewSession(calleesIds, sessionType, null, additionalOptions);

    this.videoSession = session

    var mediaParams = {
      audio: true,
      video: true,
      options: {
        muted: true,
        mirror: true,
      },
      elemId: "localVideo",
    };

    session.getUserMedia(mediaParams, function (error, stream) {
      if (error) {
        console.error("Problem with fetching user media", error)
      } else {
        // run call function here
        console.debug("Video call prepared. Starting local video.")
        session.attachMediaStream('localVideo', stream);

        var extension = {};
        session.call(extension, function(error) {
          console.debug("Video call established")
        });
      }
    });
  }

  stopVideoSession () {
    console.debug("Detaching video")
    if (this.videoSession != undefined) {
      this.videoSession.stop({})
      this.videoSession.detachMediaStream('localVideo');
    }
  }

}
