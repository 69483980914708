import ApplicationController from './application_controller'
// import { forEach } from 'lodash'

// You need to use data-controller="" with data-action="" and call stimulate()
// in script if you want to be using StimulusJs

export default class extends ApplicationController {
  static targets = [
    "btnExisting", "btnNew", "templateExisting", "templateNew", "templatePlaceholder"
  ]

  static values = {
  }

  connect () {
    super.connect()
    console.debug("Connected to ExercisePrograms controller")
    console.debug("Setting active tab to", this.data.get("activeTab"))
    this.setFormState(this.data.get("activeTab"))
  }

  // action
  create (event) {
    event.preventDefault()

    this.stimulate("ExercisePrograms#create")
  }

  // action
  switchForm(event) {
    console.debug("Switch state to", event.currentTarget.dataset.state)

    this.setFormState(event.currentTarget.dataset.state)
  }

  // ****************** Internal functions

  setFormState(state) {
    this.activateNav(state)
    this.switchTemplate()
  }

  activateNav(newState) {
    let buttons = [this.btnNewTarget, this.btnExistingTarget]
    buttons.forEach(el => el.classList.remove("active"))

    this.data.set('activeTab', newState)

    if (newState == 'new') {
      this.btnNewTarget.classList.add('active')
    } else {
      this.btnExistingTarget.classList.add('active')
    }
  }

  switchTemplate() {
    if (this.data.get('activeTab') == "new") {
      this.templatePlaceholderTarget.innerHTML = this.templateNewTarget.innerHTML
    } else {
      this.templatePlaceholderTarget.innerHTML = this.templateExistingTarget.innerHTML
    }
  }

  // --------------------------------------

  beforeCreate (element, reflex) {
    console.debug("Running beforeCreate", reflex)
    // document.activeElement.blur()
  }

  createSuccess(element, reflex, noop, reflexId) {
    console.debug("Running createSuccess", reflex)
  }

  afterCreate (element, reflex) {
    console.debug("Running afterCreate", reflex)
  }


  // createSuccess(element, reflex, noop, reflexId) {
  //   console.log("add_exercise success", element, reflex, reflexId)
  // }

  // createError(element, reflex, error, reflexId) {
  //   console.error("add_exercise error", element, reflex, error, reflexId)
  // }

  // createHalted(element, reflex, noop, reflexId) {
  //   console.warn("add_exercise halted", element, reflex, reflexId)
  // }

}
